$(document).ready(function () {
    var $window = $(window);
    var $isi = $("#isi");
    var $callout = $("#copaySlideIn");
    var showCallout = false;
    var isScrollOn = false;
    var trackedShow = false;

    function onShow() {
        scrollOn();
        httpUtility.cookie.set("copaySlideIn", "true");
        if (!trackedShow) {
            trackedShow = true;
            evoAnalytics.trackEvent($callout.find(".ga-viewed")[0].value.split("|"));
            evoke.gtm.trackEvent($callout.find(".gtm-viewed")[0].value);
        }
    }
    function onHide() {
        if (showCallout) {
            scrollOn();
        } else {
            scrollOff();
        }
    }
    function scrollOn() {
        if (!isScrollOn) {
            isScrollOn = true;
            $window.on("scroll", scrollTrigger);
        }
    }
    function scrollOff() {
        if (isScrollOn) {
            isScrollOn = false;
            $window.off("scroll", scrollTrigger);
        }
    }

    function scrollTrigger() {
        var callout = evoke.ui.TimedCallout.findCallout("#copaySlideIn");
        if (!callout.isAnimating()) {
            var scrollTop = $window.scrollTop();
            var mobileIsiVisible = evoke.core.windowWidth() < 1000 && scrollTop > $isi.offset().top + 70 - $window.height();
            if (callout.isVisible()) {
                if (mobileIsiVisible) {
                    showCallout = true;
                    scrollOff();
                    callout.hide();
                }
            } else {
                if (showCallout && !mobileIsiVisible) {
                    showCallout = false;
                    scrollOff();
                    callout.show();
                } else if (httpUtility.cookie.get("copaySlideIn") !== "true" && scrollTop > yTrigger) {
                    callout.stopTimer();
                    scrollOff();
                    callout.show();
                }
            }
        }
    }

    var yTrigger = 0;
    function calculateYTrigger() {
        yTrigger = Math.floor((evoke.$("main").offset().top + evoke.$("main").height() - $window.height()) * 0.75);
    }

    var windowWidth = $window.width();
    var currentWindowWidth = windowWidth;
    function resize() { //ios firing resize on scroll
        currentWindowWidth = $window.width();
        if (currentWindowWidth != windowWidth) {
            windowWidth = currentWindowWidth;
            calculateYTrigger();
            evoke.ui.TimedCallout.findCallout("#copaySlideIn").hide();
        }
    }

    if (httpUtility.cookie.get("copaySlideIn") !== "true") {
        evoke.ui.TimedCallout.create({
            selector: "#copaySlideIn",
            duration: 1500,
            showAfter: 30000,
            triggers: [{ selector: "a.slide-in-close", trigger: "hide" }],
            onShow: onShow,
            onHide: onHide
        });
        calculateYTrigger();
        scrollOn();
        $window.on("orientationchange", function () {
            evoke.ui.TimedCallout.findCallout("#copaySlideIn").hide();
        });
        $window.on("resize", resize);
    }
});